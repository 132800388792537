#Serv {
    background-color: #DFE3EE;
    padding: 0rem 0 4rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}

.Serv {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        display: flex;
        align-items: center;
        position: relative;
        padding: 3rem 2rem;
        border-radius: 20%;

        &::after,
        &::before {
            position: absolute;
            content: '';
            width: 8px;
            height: 30px;
            border-radius: 10px;
            background-color: #646464;
        }

        &::after {
            left: 0;
        }

        &::before {

            right: 0;
        }


    }
}

.services-sec {
    display: flex;
}

.srev-card {
    width: 270px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--main-color);
    margin: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;


    h4 {
        color: var(--sec-text-color);
        margin: 1.2rem 0 0 0;
        padding-bottom: 0;
    }

    p {
        color: var(--sec-text-color);
        font-size: 0.7rem;
    }

    i {
        display: flex !important;
        padding: 1.5rem;
        border-radius: 10px;
        color: var(--main-color);
        background-color: #f1f1f1;
        font-size: 3.5rem;
    }

    &:hover {
        background-color: #f1f1f1;

        h4,
        p {
            color: var(--main-color);
        }


        i {
            color: #f1f1f1;
            background-color: var(--main-color);
        }

        .triangle {
            z-index: 1;
            position: absolute;
            background-color: var(--main-color);
            width: 300px;
            height: 50px !important;
            bottom: -71px;
            transition: bottom 0.1s linear, background-color 0.4s ease, width 0.5s ease, height 0.5s ease;
        }

        .serv-card-bot {
            a {
                color: #f1f1f1;
            }
        }
    }


}

.serv-card-top {
    padding-top: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.triangle {
    z-index: 2;
    position: absolute;
    width: 0;
    height: 0;
    bottom: -31px;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--main-color);
    border-left: 10px solid transparent;
    transition: bottom 0.5s linear, background-color 0.1s ease, width 0.3s ease, height 0.3s ease;

}

.serv-card-bot {
    padding: 0.6rem 0;
    width: 100%;
    text-align: center;
    background: #f1f1f1;

    a {

        position: relative;
        z-index: 3;
    }
}

@media screen and (max-width:986px) {

    #Serv {
        width: 100%;
        height: 100%;
        align-items: start;
    }

    .services-sec {
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.2rem;
    }

    .Serv {
        width: 80%;
        display: flex;

        align-items: center;
    }
}