.tracing-containerVF {
    padding: 35px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.order-trackingVF {
    display: flex;
    justify-content: space-between;
}

.order-trackingVF {
    text-align: center;
    width: 20%;
    position: relative;
    display: block;
}

.order-trackingVF .is-complete {
    display: block;
    position: relative;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    border: 3px solid #9399A7;
    margin: 0 auto;
    transition: background 0.25s linear;
    z-index: 0;
}

.order-trackingVF .is-complete:after {
    position: absolute;
    content: '';
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    margin: auto 0;
    border: 0px solid #AFAFAF;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

.order-trackingVF.completedVF .is-complete {
    background-color: var(--main-color);
    border: 3px solid var(--main-color);
}

.order-trackingVF.completedVF .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 42%;
    right: 42%;
    opacity: 1;
}

.order-trackingVF.currentVF .is-complete {
    /* background-color: var(--main-color); */
    border: 3px solid var(--main-color);
    color: white;

}

.order-trackingVF p {
    color: #A4A4A4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
}

.order-trackingVF.completedVF p {
    color: #000;
}

.order-trackingVF.currentVF p {
    color: var(--main-color);
}

.order-trackingVF::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 43px);
    left: calc(-50% + 22px);
    background-color: #9399A7;
    top: 20px;
    position: absolute;
    z-index: 0;
}

.order-trackingVF:first-child::before {
    display: none;
}

.order-trackingVF.completedVF::before {
    background-color: var(--main-color);
}

.order-trackingVF.currentVF::before {
    background-color: var(--main-color);

}

.checkmarkVF {
    display: none;
}

.order-trackingVF.completedVF .checkmark {
    display: inline-block;
    font-size: 18px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.canceled-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        color: var(--main-color);
        text-decoration: underline !important;
    }
}

.canceled {
    margin: 0;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(252, 117, 117);
    width: 45px;
    height: 45px;
}

/* @media screen and (max-width:1024px) {
    .tracing-containerVF {
        padding: 35px;
        width: 45rem;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

*/
@media screen and (max-width:635px) {
    .tracing-containerVF {
        padding: 0;

        margin: 20px 0;
        width: 100%;



    }


    .order-trackingVF p {

        font-size: 12px;

    }
}