.showcase {
    position: relative;
    height: 100%;
    width: 100%;

    .secEnd {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
        width: 70px;
    }
}

.showcase__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showcase__content {
    width: 40%;
    margin-right: 10%;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl;
    color: var(--main-text-color);

    h2 {
        color: var(--main-color);
        padding-bottom: 1rem;
    }

    .G-btn {
        position: relative;
        transition: all 0.2s ease;

        &:hover {
            background-color: #222;
        }
    }
}

.showcase__img {
    transform: translateX(-0%);

    img {
        width: 72%;
    }
}




@media screen and (max-width:1200px) {
    .showcase {
        .secEnd {
            width: 55px;
        }
    }

    .showcase__content {
        width: 50%;


        h2 {
            font-size: 1.3rem;

        }

    }

}

@media screen and (max-width:990px) {
    .showcase__content h2 {
        padding-top: 1rem;
        font-size: 1.15rem;
    }

}

@media screen and (max-width:850px) {
    .showcase__container {

        flex-direction: column;
    }

    .showcase .secEnd {
        bottom: 0rem;
        left: 2rem;
    }

    .showcase__content {
        width: 100%;
        padding-bottom: 2rem;

        float: end;
        padding-bottom: 5rem;

        h2 {
            font-size: 1.4rem;
            width: 60%;
            color: var(--main-color);
            padding-bottom: 1rem;
        }

    }

}

@media screen and (max-width:850px) {

    .showcase__content h2 {
        font-size: 1.4rem;
        width: 90%;
        color: var(--main-color);
        padding-bottom: 1rem;
    }
}

@media screen and (max-width:550px) {

    .showcase__content h2 {
        font-size: 1.2rem;

    }

    .showcase .secEnd {
        bottom: 1.5rem;
        left: 2rem;
    }

}