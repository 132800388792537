.tracing-container {
    padding: 35px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tracing-row {
    display: flex;
    justify-content: space-between;
}

.order-tracking {
    text-align: center;
    width: 33.33%;
    position: relative;
    display: block;
}

.order-tracking .is-complete {
    display: block;
    position: relative;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    border: 3px solid #9399A7;
    margin: 0 auto;
    transition: background 0.25s linear;
    z-index: 2;
}

.order-tracking .is-complete:after {

    position: absolute;
    content: '';
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    margin: auto 0;
    border: 0px solid #AFAFAF;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

.order-tracking.completed .is-complete {
    background-color: var(--main-color);
    border: 3px solid var(--main-color);
}

.order-tracking.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 42%;
    right: 42%;
    opacity: 1;
}

.order-tracking.current .is-complete {

    border: 3px solid var(--main-color);
}

.order-tracking p {
    color: #A4A4A4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
}

.order-tracking.completed p {
    color: #000;
}

.order-tracking.current p {
    color: var(--main-color);
}

.order-tracking::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 44px);
    left: calc(-50% + 22px);
    background-color: #9399A7;
    top: 20px;
    position: absolute;
    z-index: 0;
}

.order-tracking:first-child::before {
    display: none;
}

.order-tracking.completed::before {
    background-color: var(--main-color);
}

.order-tracking.current::before {
    background-color: var(--main-color);
}

.checkmark {
    display: none;
}

.order-tracking.completed .checkmark {
    display: inline-block;
    font-size: 18px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}