@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  --sec-color: #DFE3EE;
  --main-color: #4463AF;
  --color-bg: #4463AF;
  --main-color-op: #4462af77;
  --main-text-color: #000;
  --main-text-color-op: #222222c5;
  --sec-text-color: #fff;
}

* {
  font-family: "Cairo", sans-serif;
  list-style: none;
  box-sizing: border-box;

}

body {
  background-color: #f1f1f1;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: var(--main-text-color);
}

.containerr {
  width: 90%;
  margin: 0rem 4%;
}

.G-btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 10px;
  padding: 0.4rem 2.2rem;
  transition: all 0.2s linear;
}

.G-btnH:hover {
  transition: all 0.2s linear;
  background-color: #222222;
}

.G-Link {
  font-weight: bold;
  transition: all 0.2s linear;

  &:hover {
    color: var(--color-bg);
    margin-right: 0.5rem;
  }
}

.G-btn2 {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--sec-color);
  color: #000;
  border-radius: 10px;
  padding: 0.4rem 2.2rem;
}

@media print {
  body {
    direction: rtl;
    text-align: right;
    /* Align text to the right for RTL */
  }
}

/* .modal-overlay {
  z-index: 1100 !important;
} */

/* @media screen and (max-width: 1070px) {

  .sectionH h2 {
    font-size: 1.7rem;

  }

  .section-more-btn {
    font-size: 1rem;
  }
} */