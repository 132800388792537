#FollowUp {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;


    .cust-input {
        width: 50%;
        border-radius: 5px !important;
        border: 1px solid var(--main-color-op);
        outline: none;
        margin-top: 0.2rem;
        min-height: 37px;

        &::placeholder {
            padding: 0.4rem;
        }

        &:focus {
            border: 1px solid var(--main-color) !important;
        }
    }
}

.order-details {
    width: 80%;
    margin: 1rem 1rem 0;
    background-color: var(--sec-color);
    border-top: 4px solid var(--main-color);
    border-bottom: 4px solid var(--main-color);
    box-shadow: 5px 5px 5px -4px #222;
    border-radius: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1.6rem;

    p {
        padding: 0.3rem 0;
        margin: 0;
    }

    h4 {
        margin-top: 0;
    }


    .Ord-status {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 1.3rem;
        color: var(--main-color);
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        padding: 1rem 0.5rem;

        strong {
            width: 100%;
            text-align: center;

        }
    }

    .inform {
        margin-top: 0.7rem;
        font-size: 1.1rem;

        a {
            text-decoration: underline !important;
            color: var(--main-color);
        }
    }

    .prtItems-container {
        width: 75%;
        justify-content: space-between;

        .prtItem {
            padding: 0.5rem 0;
            font-size: 1rem;
        }
    }
}


@media screen and (max-width:635px) {
    .order-details {
        width: 97%;
        margin-left: 1rem;
        margin-right: 1rem;

        .prtItems-container {
            flex-direction: column;
            align-items: start;
            gap: 1.5rem;
        }
    }

    .Ord-status {
        p {
            font-size: 1.1rem;
        }
    }
}