#ord-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
        color: #042638;
    }
}

.ord-details-table {
    background-color: var(--sec-color);
    border-top: 4px solid var(--main-color);
    border-bottom: 4px solid var(--main-color);
    padding: 0 2rem 2rem;
    border-radius: 1rem;
}

.ord-table-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ord-table-2 {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;

    .prtItems {
        p {
            margin: 0.4rem 0;
        }

    }

}

.prtItem8 {
    background-color: #f4f4f4;
    padding: 0.2rem 1rem;
    border: 2px solid #bbbaba;
    border-radius: 5px;
}

.prtForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 595px;
    height: 842px;
    position: relative;

    /* background: url('../../../assets/Print-Form_pages.jpg') center center/cover no-repeat; */
    img {
        position: absolute;
        width: 595px;
        height: 842px;
        margin: 0;
        padding: 0;
    }


}

.prtItems-container {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.prtItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;

    p {
        margin-bottom: 0;
        margin-top: 0.3rem;
    }


}

.prtItem7 {
    font-size: 0.8rem;
    bottom: -2.55rem;
    right: 0.5rem;
    position: relative;

    p {
        overflow: scroll;
        height: auto;
        height: 5rem;
        width: 16rem;
    }

}

.prtItem {
    font-size: 0.75rem;

}

.ord-details {
    position: absolute;
    width: 525px;
    margin-top: 8.5rem;
}

.ord-details2 {
    position: relative;
    /* 
    .prtItem {
        position: absolute;
    } */
}

@media screen and (max-width:500px) {
    #ord-details {
        margin-top: 2rem;
    }
}

/* OrderDetails.css */
.prtForm {
    width: 595px;
    /* Set the width to match the print size */
    height: 842px;
    /* Set the height to match the print size */
    overflow: hidden;
    /* Prevent overflow */
    position: relative;
    /* For proper positioning of contents */
    background-color: #fdfdfd;
}