#footer {
    direction: rtl;
    background-color: var(--main-color);
    background-position: top;
    height: 100%;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1.5rem;
}

.footer .column {
    margin: 1rem 0 0;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    padding: 0 15px;
    box-sizing: border-box;
}

.column a {
    color: #fff;
    cursor: pointer;
}


#footer .bord {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    border: none;
    color: #fff;
}

footer a:hover {
    color: #fff;
}

footer a {
    color: #fff !important;
    font-family: "Fredoka", sans-serif;
    display: block;
}


.under-border {
    width: 60%;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--sec-color);
}

#footer .bord p {
    margin-bottom: 0.5rem;
    font-family: "Fredoka", sans-serif;
    font-size: 12px;
}

#footer .column .aboute p {
    font-family: "Fredoka", sans-serif;
    line-height: 1.4rem;
    font-size: 14px;
}

#footer .bord i {
    font-family: "Fredoka", sans-serif;
    padding: 0 10px;
    font-size: 18px;
}


#footer ul {
    padding: 0 1.5rem;
}

#footer li {
    margin-bottom: 15px;
}


/* Optional: You can style each column individually */
.footer .column h3 {
    font-family: "Fredoka", sans-serif;
    color: #fff;
    font-size: 16px;
    display: block;
    border-bottom: 1px solid var(--sec-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.footer .column p {
    font-size: 14px;
    font-family: "Fredoka", sans-serif;
}

.cAbout {
    transition: all 0.2s linear;
}

.cAbout:hover {
    transition: all 0.2s linear;
    transform: scale(1.05);
}

.footer-end {
    font-size: auto;
    padding: 0;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-end h3 {
    display: flex;
    justify-content: center;
    align-items: end;
    font-size: 1rem;
    font-family: "Cairo", sans-serif;
}

.footer-end span a,
.footer-end span {
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0 4px;
    font-family: "Cairo", sans-serif !important;
    color: var(--main-color) !important;
    border-bottom: 1px solid transparent;
    transition: all 0.2s linear;


    .showLink {
        transition: all 0.5s linear;
        transform: translateX(0%);
    }
}

.footer-end span {
    transition: all 0.2s linear;
    transform: translateX(126%);

    a {
        border: none;
        font-size: 0.8rem;
    }

}

.bg-cover {
    transform: translatex(40%);
    height: 100%;
    width: 100%;
    padding-right: 10rem;
    z-index: 2;
    background-color: #F1F1F1;
}

.footer-end span:hover {
    transition: all 0.2s linear;
    transform: translateX(55%);

    .showLink {
        transition: all 0.2s linear;
        display: block;
    }

}

.mobile {
    display: flex;
    justify-content: space-around;
    width: 70%;
}

/* Media queries for responsiveness */

@media screen and (max-width: 1024px) {

    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile {
        width: 100%;
    }

    .footer .column {
        width: 100%;
        padding-bottom: 10px;
    }

    #footer ul {
        padding: 0;
    }

    .footer-end span {
        transition: all 0.2s linear;
        transform: translateX(0%);

        a {
            border: none;
            font-size: 0.8rem;
        }

    }


    #footer .bord i {
        font-family: "Fredoka", sans-serif;
        padding: 0 0px;
        font-size: 18px;
    }


    .showLink {
        margin: 0;
        display: none !important;
    }

    .bg-cover {
        padding-right: 0;
        transform: translatex(0%);
    }

}


@media screen and (max-width: 576px) {

    .footer-end {
        padding-top: 0.3rem;

        h3 {
            font-size: 0.6rem;

            span a {
                font-size: 0.6rem;
            }
        }
    }

    #footer .bord p {
        font-size: 8px;
    }

}

@media screen and (max-width: 375px) {
    .footer .Fbord p {
        font-size: 8px;
        padding-bottom: 2px;
    }

    #footer .column a {
        font-size: 8px;
    }

}