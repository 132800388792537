.About {
    padding-top: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.About .about__content {
    ul li {
        font-size: 1.2rem;

        span {
            color: var(--main-color);
        }

        list-style: disc !important;
    }
}


@media screen and (max-width:850px) {
    .About {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
    }

    .About .about__content {
        margin-right: 1rem;

        ul {

            li {
                font-size: 1rem;

                span {
                    color: var(--main-color);
                }

                list-style: disc !important;
            }
        }
    }

}