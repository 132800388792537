.adminSB {
    width: 230px;
    background-color: var(--main-color);
    max-height: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;

    top: 4rem;
    left: 0;
    z-index: 3;
    position: absolute;
    border-radius: 0 15px 15px 0;
    width: 98.5%;
    min-height: 2rem;
}

.adminSB-links {
    padding-top: 1rem;
    padding: 0.5rem;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1070px) {
    .adminSB {
        top: 0;
        left: 0;
        z-index: 3;
        position: absolute;
        width: 50%;
        min-height: 2rem;

    }


    .adminSB-links {
        padding: 0.5rem;
        display: flex;
        gap: 1rem;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:500px) {
    .adminSB {
        top: 4rem;
        left: 0;
        z-index: 3;
        position: absolute;
        border-radius: 0 15px 15px 0;
        width: 98.5%;
        min-height: 2rem;
    }
}