.p-dialog {
    border-radius: 0.7rem;
    overflow: hidden;
    border-top: 4px solid var(--main-color);
    border-bottom: 4px solid var(--main-color);
}

.p-dialog,
.p-dialog-header,
.p-dialog-content {
    background-color: var(--sec-color);

    textarea {
        background-color: #f1f1f1;
    }
}