.admin-main-nav {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem 0;
    margin: 0 0 0.5rem 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0 0 10px 10px;
    background-color: var(--main-color);
}

.admin-main {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

}

.admin-pages {

    width: 90%;
    margin: 4rem 2rem 2rem;
    display: flex;
    justify-content: center;

}

.admin-pagesS {
    overflow-x: auto;
}

.table-header {
    display: flex;
    gap: 2rem;
    align-items: end;
    flex-direction: column;

    .table-headerr {
        align-items: center;
        height: 2.8rem;
        display: flex;
        gap: 1rem;
    }
}

@media screen and (max-width:1070px) {
    .admin-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

    }

    .admin-pages {
        width: 90%;
        margin: 2rem;
        display: flex;
        justify-content: center;

    }

}

@media screen and (max-width:500px) {
    .admin-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

    }

    .admin-pagesS {
        overflow-x: scroll !important;
    }

    .table-header {
        margin-top: 1.5rem;
        display: flex;
        gap: 4rem;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.2rem;

        input {
            margin-bottom: 0.7rem;
        }
    }

    .admin-pages {
        overflow: scroll !important;
        width: 90%;
        margin: 2rem;
        display: flex;
        justify-content: center;

    }

}