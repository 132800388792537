#verify {
    width: 30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.form-head {
    position: relative;
    top: 1%;
    text-align: center;
}

.phone-input1 {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 1rem;
    width: 100%;
    height: 40px;
    padding: 0 0.5rem;
    background-color: #fff;

    p {
        font-size: 1rem;
        width: 3.6rem;
    }

}

/*
.T-btn {
    position: absolute;
    text-decoration: underline;
    background-color: transparent;
    color: black;
    right: 0;
    padding-top: 1rem;
} */

.form-content {
    width: 80%;

    input {
        width: 100%;
        height: 2.5rem;
        border: 1px solid var(--main-color-op);
        border-radius: 5px;
        margin: 5% 0;

        &::placeholder {
            padding: 0.5rem;
        }
    }

    button {
        float: right;
        width: 45%;
    }

    .btn-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width:1320px) {

    #verify {
        width: 50vw;

    }
}

@media screen and (max-width:720px) {

    #verify {
        width: 90vw;
        padding: 0 2rem;

        button {
            width: 100%;
        }
    }

    .btn-container {
        flex-direction: column;
        gap: 0.6rem;

    }

    .form-content {
        width: 100%;
    }
}