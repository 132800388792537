#About {
    background-color: #DFE3EE;
    margin: 2rem 0 0;
    padding: 2rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about__img {
    img {
        width: 70%;
    }
}

.about__content {
    padding-bottom: 3rem;
    margin-right: 10%;
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;

    /* h1 {} */

    h3 {
        color: var(--main-color);
        width: 80%;
    }
}

@media screen and (max-width:1250px) {

    .about__content {
        width: 90%;

        h1 {
            font-size: 1.8rem;
        }

        h3 {
            color: var(--main-color);
            width: 80%;
        }
    }
}

@media screen and (max-width:850px) {
    #About {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
    }

    .about__img {
        display: flex;
        justify-content: start;
        align-items: start;

        img {
            width: 75%;
        }
    }

    .about__content {
        padding-bottom: 3rem;
        margin: 0;
        direction: rtl;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 90%;

        h3 {
            color: var(--main-color);
            width: 90%;
        }
    }
}

@media screen and (max-width:650px) {

    .about__content {

        h3 {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width:500px) {

    .about__content {

        h3 {
            font-size: 1.1rem;
        }
    }
}