.navbar {
    position: fixed;
    z-index: 50;
    width: 100%;
    color: #fff;
    background: var(--main-color);
    display: flex;
    align-items: center;
    padding-top: 0.3rem;
    padding-bottom: 0 !important;
    transition: all ease-in-out 0.4s;
    box-shadow: 0px 2px 10px #00000034;
}

.nav-container {
    width: 95%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    align-items: center;
}

.menu {
    display: none;
    position: absolute;
    top: 0.5rem;
    left: 0%;
    color: #f1f1f1;
    transition: all ease-in-out 0.4s;
    border-radius: 0 10px 10px 0;
    background-color: var(--main-color);
    font-size: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 0.8rem;
    transition: all ease-in-out 0.4s;

    i {

        display: flex !important;
    }
}



.logo img {
    margin-bottom: 0;
    padding-bottom: 0;
    width: 6rem !important;
}

.logo {
    border-bottom: none !important;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.7rem;
    padding-bottom: 0;
}



.topnav {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}

.topnav a {
    cursor: pointer;
    color: #fff;
    text-align: center;
    padding: 14px 40px 24px 40px;
    margin-bottom: 0;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    border: 1px transparent;
    margin-top: 16px;

}


.navbar a {
    transition: all ease-in-out 0.2s;
    border-bottom: 1px solid transparent;

    &:hover {
        transition: all ease-in-out 0.2s;
        border-bottom: 1px solid #CAA457;
    }
}

.logo:hover {
    border-bottom: none !important;
    background-color: #22222200 !important;
}


.navbar {
    background-color: var(--main-color);
    transition: all ease-in-out 0.4s;


    a {
        border-bottom: none;
        color: #f1f1f1;
    }
}

.navbar .topnav a::before {
    border-radius: 10px 10px 0 0;
    background: #f1f1f1;
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    transform: scaley(0);
    transform-origin: bottom;
    transition: transform 0.5s ease-in-out;
    z-index: -1;
}


.navbar .topnav a:hover::before {
    transform: scaley(1);
    border-radius: 10px 10px 0 0;
    transform-origin: bottom;
}


.navbar .topnav a:hover {
    border-bottom: 00px solid #CAA457;
    background-color: #f1f1f1;
    border-radius: 10px 10px 0 0;
    color: #000000;
    transition: linear 0.6s;
}

.Nactive {
    color: #000000 !important;
    border-radius: 10px 10px 0 0;
    background-color: #f1f1f1;
    transition: color 0.8s, background-color 0.8s;
}

.navbar .topnav a.hovered {
    background-color: none;
    border-radius: 10px 10px 0 0;
    color: #f1f1f1;
}

.close-btn {
    color: #f1f1f1;
    display: none;
}

.nave-links {
    display: none;

    i {
        display: flex !important;
    }
}


@media screen and (max-width: 1200px) {
    .topnav a {
        font-size: 17px;
    }


}

@media screen and (max-width: 1070px) {
    .topnav a {
        padding: 14px 20px;
        font-size: 15px;
    }



    .logo {
        padding-left: 5.5rem;
    }

}

@media screen and (max-width: 790px) {

    .navbar {
        background: transparent;
        height: 4rem;
        box-shadow: 0px 0px 0px #00000000;

        .logo {
            display: none;
        }
    }

    .topnav {
        background-color: var(--main-color);
        width: 65%;
        height: 120vh;
        padding-top: 10vh;
        position: absolute;
        top: 0rem;
        left: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        opacity: 0;
        transform: translateX(-100%) !important;
        transition: all 0.5s ease;

        a {
            color: #f1f1f1;
        }
    }

    .nave-links {
        width: 100%;
        display: flex !important;
        justify-content: center;
        margin-top: 2rem;

        a {

            border-radius: 10px 10px 0 0;
            border: 1px solid #f1f1f1;
            color: #f1f1f1;
            font-size: 1.5rem;
            padding: 0.65rem;
            margin: 0.3rem;
        }

    }

    .close-btn {
        position: absolute;
        display: block;
        font-size: 2rem;
        top: 6vh;
        right: 1.5rem;
    }

    .Svisible {
        opacity: 1;
        transform: translateX(0%) !important;
    }


    .menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .topnav a::before {
        border-radius: 10px 10px 0 0;
        background: var(--color-bg);
        color: #CAA457;
        content: "";
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        position: absolute;
        transform: scaley(0);
        transform-origin: bottom;
        transition: transform 0.5s ease-in-out;
        z-index: -1;
    }


    .topnav a:hover::before {
        transform: scaley(1);
        border-radius: 10px 10px 0 0;
        transform-origin: bottom;
    }


    .topnav a:hover {
        border-bottom: 00px solid #CAA457;
        background-color: var(--color-bg);
        border-radius: 10px 10px 0 0;
        color: #fff;
        transition: linear 0.6s;
    }

}