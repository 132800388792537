#contact {
    height: 100%;
    width: 88.5%;

    margin: 15px 0;
    padding-top: 15px;
    padding-bottom: 120px;
}


.contact-container {
    margin: 45px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        width: 100%;
        text-align: start;
    }
}



.conatct-top {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 5%;

}

.cont-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
}

.cont-right {
    width: 40%;

    img {
        width: 100%;
    }
}


.cont-input {
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}


.form-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0 0 !important;
}


.top-col-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


#contact form input {
    margin: 10px 20px;
    padding: 0.5rem;
    width: 30rem;
    color: black;
    border-radius: 3px;
    border: 1.5px solid #2222224d;
}


#contact textarea {
    margin: 10px 0px 10px 20px !important;
    padding: 4px !important;
    width: 100%;
    height: 10vh;
    color: black !important;
    border-radius: 5px;
    border: 1.5px solid #2222224d;
}

input::placeholder {
    margin-right: 10px;
    font-weight: 450;

}


textarea::placeholder {
    margin: 10px 10px 0 0;
    font-weight: 6550;
}

.col-but {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.btn-container {

    margin: 0 1.5%;
}


.bord {
    display: flex;
    align-items: center;
    color: var(--main-color);
    font-size: 20px;
    border: 1px solid var(--main-color);
    border-radius: 10px 10px 0 0;
    margin-bottom: 0.8rem;
    padding: 3px 2px;

    p {
        margin: 0.3rem;
    }
}

.bord i {
    margin: 2px 15px 2px 15px;
    display: block;
    font-size: 20px;
}

.bordI {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        font-size: 2rem;
        color: var(--main-color);
        margin: 0.7rem;
        border: 1px solid var(--main-color);
        border-radius: 5px 5px 0 0;
        padding: 0.4rem;
        transition: all 0.2s ease;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            color: #fff;
            background-color: var(--main-color);
        }
    }
}



.contactH {

    text-align: end;
}

#contact span {
    padding: 0.5rem 1.5rem;
}


/* @media screen and (max-height:900px) {} */

@media only screen and (max-width: 1840px) {
    .bord {
        font-size: 14px;
    }

    iframe {
        width: 100%;
        height: 265px;
    }



    #contact form input {
        width: 35rem;
    }

}

@media only screen and (max-width: 1366px) {
    .bord {
        font-size: 14px;
    }

    iframe {
        width: 100%;
        height: 265px;
    }



    #contact form input {
        width: 35rem;
    }

}

@media screen and (max-width:1302px) {


    #contact form input {
        width: 32rem;
    }
}


@media screen and (max-width:1250px) {


    #contact form input {
        width: 25rem;
    }

}


@media screen and (max-width:1224px) {
    #contact h1 {
        font-size: 22px;
    }

    .contact-but h2 {
        font-size: 20px;
    }

    .bord {
        font-size: 14px;
    }

    iframe {
        width: 100%;
        height: 290px;
    }



    #contact form input {
        width: 22rem;
    }

    .top-col-row {
        flex-direction: column;
    }
}


@media screen and (max-width:900px) {



    #contact form input {
        width: 10rem;
    }
}

/* Media queries For small Devices */
@media screen and (max-width:780px) {
    .conatct-top {
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;

        .cont-left,
        .cont-right {
            width: 90%;

        }

        iframe {
            margin-bottom: 2rem;
        }
    }


    #contact form input {
        width: 18rem;
    }

    .contact-but h2 {
        margin-top: 30px !important;
    }
}

@media screen and (max-width:710px) {


    textarea {
        width: 100%;

    }

    .contact-but h2 {
        margin-top: 30px !important;
    }

    .btn-container {
        width: 90%;
        margin: 0;
        display: flex;
        justify-content: start;
    }
}

@media screen and (max-width:650px) {


    .contact-container {
        margin: 10px;
    }


    .cont-left {
        width: 80%;
        padding-bottom: 20px;
    }

    .cont-right {
        width: 80%;
        padding: 0;

    }

    #contact h1 {
        font-size: 20px;
    }

    .contact-but h2 {
        margin-top: 10px;
        font-size: 18px;
    }


    .col-but {
        justify-content: center;
    }

}


@media screen and (max-width:450px) {


    .bord {
        font-size: 12px;
    }

    /* #contact form input {
        width: 18rem;
    } */

    .bordI {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            font-size: 1.5rem;
            color: var(--main-color);
            margin: 0.4rem;
            border: 1px solid var(--main-color);
            border-radius: 5px 5px 0 0;
            padding: 0.4rem;
        }
    }

}

@media screen and (max-width:290px) {

    .bord {
        font-size: 12px;
    }
}