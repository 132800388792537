#Ord__data {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.react-tel-input .form-control {
    width: 100% !important;
}

.ISD {
    position: absolute;
    left: 0.3rem;
    top: 51%
}

.phoneL {
    margin-right: 0.2rem;
    float: right;
}

.ISD-in {
    padding-left: 2.5rem;
}

.form_ord__data {
    width: 60vw;

    input,
    textarea,
    .css-13cymwt-control {
        border-radius: 5px !important;
        border: 1px solid #22222236;
    }


    .cust-input {
        margin-top: 0.2rem;
        min-height: 37px;


        &::placeholder {
            padding: 0.4rem;
        }
    }

    input,
    label,
    textarea,
    .css-13cymwt-control {
        width: 100%;
        outline: none;


        &:focus {
            border: 1px solid var(--main-color) !important;
        }
    }

    .serv-name {
        width: 50%;
        margin-bottom: 0.6rem;
        background-color: #f0f0f0;
        cursor: not-allowed;
        user-Select: none;
        color: black;
        font-weight: bold;
        padding-right: "0.5rem"
    }

    .ord-form-row {
        margin-bottom: 1rem;
        display: flex;
        gap: 1rem;
        align-items: center;

        label {
            font-size: 0.8rem;
        }
    }

    textarea {
        margin-bottom: 0.6rem;
    }
}

.form-head {
    position: relative;
    top: 10%;
    text-align: center;
}


@media screen and (max-width:850px) {

    .form_ord__data {
        width: 80vw;
    }

}

@media screen and (max-width:650px) {


    #Ord__data {
        width: 100%;
    }

    .form_ord__data {

        .ord-form-row {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            label {
                font-size: 0.8rem;
            }
        }

        .cust-input {
            margin-top: 0.2rem;


            &::placeholder {
                padding: 0.4rem;
            }
        }

        .serv-name {
            height: 40px;
            margin-top: 0.7rem;
            width: 100%;
            padding-right: 0.4rem;
        }


        textarea {
            margin-bottom: 0.6rem;
        }
    }

}