#Login {
    background-color: var(--sec-color);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control {
    color: black;
}

.LogForm {
    display: flex;
    justify-content: center;
    align-items: center;

    form {

        width: 100%;
        text-align: center;

        input {
            color: black !important;
        }
    }
}

.log-bg {
    background-color: var(--sec-color);
}