#Order {
    padding-top: 8rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        border: none;
        outline: none;
    }


}

.Order {
    overflow: hidden;
    position: relative;
    border-top: 4px solid var(--main-color);
    border-bottom: 4px solid var(--main-color);
    max-width: 75%;
    padding: 2rem;
    height: 100%;
    border-radius: 15px;
    background-color: var(--sec-color);
    box-shadow: 10px 10px 20px -10px #222;
    display: flex;
    justify-content: center;
}

.order-done {
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    i {
        display: flex !important;
        padding: 0rem !important;
        border-radius: 50%;
        border: 2px solid var(--main-color);

        margin: 0;
        padding: 0;
        font-size: 7rem;
    }

    h5 {
        margin-top: 0;
        color: black;

        a {
            color: var(--main-color);
            text-decoration: underline !important;
        }
    }
}

@media screen and (max-width:975px) {

    #Order {
        min-height: 100%;
        padding-top: 2rem;
    }

    .Order {

        max-width: 90%;

        h2,
        h3 {
            font-size: 1.2rem;
        }
    }

}

@media screen and (max-width:650px) {
    .Order {

        h2,
        h3 {
            font-size: 1rem;
        }
    }

}

@media screen and (max-width:480px) {
    .Order {

        h2,
        h3 {
            text-align: end;
            width: 100% !important;
            font-size: 0.8rem;
        }
    }

}